import {
  Box,
  FormControlLabel,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import * as Yup from "yup";
import { useFormik } from "formik";
import Skeleton from "react-loading-skeleton";
import { toast } from "react-toastify";
import moment from "moment";
import CustomTextField from "../../../Components/CustomTextField/CustomTextField";
import CustomDropDown from "../../../Components/CustomDropDown/CustomDropDown";
import CustomLoaderButton from "../../../Components/CustomLoaderButton/CustomLoaderButton";
import CustomThumbUpload from "../../../Components/CustomFileUpload/CustomThumbUpload";
import CustomDateTimePicker from "../../../Components/CustomDatePicker/CustomDatePicker";
import AxiosInstance from "../../../Config/AxiosInstance";
import { ApiList } from "../../../Config/ApiList";
import Constant from "../../../utills/Constant";
import dayjs from "dayjs";
const AddAffirmativeTip = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [selectedImagePrev, setSelectedImagePrev] = useState(null);
  const [selectedImageFile, setSelectedImageFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [editAffirmation, setEditAffirmation] = useState({});
  const [typeCheck, setTypeCheck] = useState(null);
  const dropList = [
    { id: 1, name: "Image", value: 1 },
    { id: 2, name: "Video", value: 2 },
  ];

  useEffect(() => {
    if (location?.state?.affirmativeID) {
      getAffirmativeDetails(location?.state?.affirmativeID);
      setTypeCheck(location?.state?.type);
    }
  }, [typeCheck, location?.state?.affirmativeID]);

  const handleOnChangeImages = (acceptedFiles, rejectedFiles) => {
    if (rejectedFiles.length > 0) {
      toast.error("Invalid file type for thumb!");
    } else {
      const file = acceptedFiles[0];
      if (
        file &&
        ["image/jpeg", "image/png", "image/gif"].includes(file.type)
      ) {
        if (file.size <= 2 * 1024 * 1024) {
          const videoUrl = URL.createObjectURL(file);
          setSelectedImageFile(file);
          setSelectedImagePrev(videoUrl);
          formik.setFieldValue("thumb", file);
        } else {
          toast.error(`File size exceeds the limit of 2MB`);
        }
      } else {
        toast.error("Upload only (PNG,JPEG,WEBP,JPG)!");
      }
    }
  };

  const getAffirmativeDetails = async (id) => {
    try {
      const res = await AxiosInstance.get(ApiList.GET_BY_ID, {
        params: {
          type: 4,
          id: id,
        },
      });
      if (res && res?.data?.s === 1) {
        setEditAffirmation(res?.data?.r);
        setSelectedImagePrev(`${Constant.BASE_URL}${res?.data?.r?.thumb}`);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      selectDropDown: (typeCheck && editAffirmation?.media_type) || 0,
      videoLink: (typeCheck && editAffirmation?.link) || "",
      thumb: (typeCheck && editAffirmation?.thumb) || null,
      scheduleSelected: (typeCheck && editAffirmation?.schedule_date !== null) ? "2" : "1",
      timeSelected: (typeCheck && editAffirmation?.schedule_date) ? dayjs(editAffirmation?.schedule_date) : null,
      title: (typeCheck && editAffirmation?.title) || "",
      description: (typeCheck && editAffirmation?.description) || "",
    },
    validationSchema: Yup.object().shape({
      selectDropDown: Yup.mixed()
        .test(
          "is-not-zero",
          "Category name is required!",
          (value) => value !== 0
        )
        .required("Field is required!"),
      thumb: Yup.string().required("Thumb is required"),
      title: Yup.string().required("Title is required"),
      description: Yup.string().required("Description is required"),
      videoLink: Yup.string()
      .test(
        "video-link-required",
        "Video link is required",
        function (value) {
          const { selectDropDown } = this.parent;
          if (selectDropDown === 2 && !value) {
            return false;
          }
          return true;
        }
      )
      .test(
        "valid-url",
        "Please Provide a valid link",
        function (value) {
          const { selectDropDown } = this.parent;
          if (selectDropDown === 2 && value) {
            const urlPattern = Constant.REGEX.WEBSITEURL;
            return urlPattern.test(value);
          }
          return true;
        }
      ),

    }),
    onSubmit: async (values, { resetForm }) => {
      setIsLoading(true);
      if (isLoading) {
        setIsLoading(false);
        return;
      }
      const formData = new FormData();
      if (typeCheck === "Edit") {
        formData.append("id", editAffirmation.id);
      }
      if (selectedImageFile) {
        formData.append("thumb", selectedImageFile);
      }
      formData.append("media_type", values.selectDropDown);
      if (values.selectDropDown === 2) {
        formData.append('link', values.videoLink);
      }
      formData.append("title", values.title);
      formData.append("description", values.description);
      formData.append("is_admin", 1);

      if (values.scheduleSelected === "2") {
        const scheduleDate = moment(values.timeSelected?.$d)
          .utc()
          .format("YYYY-MM-DD HH:mm:ss");
        formData.append("schedule_date", scheduleDate);
      }
      try {
        const apiEndpoint =
          typeCheck === "Edit"
            ? ApiList.EDIT_DELETE_AFFIRMNATION
            : ApiList.ADD_AFFIRMATION;
        const response = await AxiosInstance.post(
          apiEndpoint,
          formData
        );

        if (response && response.data?.s) {
          toast.success(response.data.m);
          resetForm();
          setSelectedImageFile(null);
          setSelectedImagePrev(null);
          setIsLoading(false);
          navigate(-1);
        }
      } catch (error) {
        toast.error(error.message);
        setIsLoading(false);
      }
    },
  });
  return (
    <>
      <Box sx={{ width: "100%", px: { xs: 2, md: 5 }, py: { xs: 1, md: 3 } }}>
        <Box
          sx={{
            display: "flex",
            position: "fixed",
            top: "0",
            left: "0px",
            p: 1,
            width: "100%",
            justifyContent: "space-between",
            alignItems: "center",
            bgcolor: "white",
            boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
            zIndex: "2",
          }}
        >
          <Box sx={{}}>
            <IconButton
              style={{
                cursor: "pointer",
                fontWeight: "500",
                fontSize: "22px",
                textDecoration: "none",
                color: "black",
                width: "50px",
                height: "50px",
                borderRadius: "50%",
                border: "1px solid #0000003D",
              }}
              onClick={() => navigate(-1)}
            >
              <ArrowBackIcon />
            </IconButton>
          </Box>
          <Box sx={{ textAlign: "center" }}>
            <Typography
              sx={{
                fontSize: { xs: "18px", md: "24px", xl: "30px" },
                fontWeight: "500",
              }}
            >
              {typeCheck ? "Update" : "Add"} Daily Divine Affirmation
            </Typography>
          </Box>
          <Box></Box>
        </Box>
        <Box
          sx={{
            padding: "100px 0px 20px 0px",
            maxWidth: { xs: "100%", md: "90%", lg: "80%", xl: "70%" },
            margin: "0 auto",
            width: "100%",
          }}
        >
          <form
            onSubmit={(e) => {
              e.preventDefault();
              formik.handleSubmit();
              return false;
            }}
          >
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Box sx={{ width: "470px", margin: "0 auto" }}>
                  <CustomThumbUpload
                    accept="image/*"
                    onDrop={(acceptedFiles, rejectedFiles) =>
                      handleOnChangeImages(acceptedFiles, rejectedFiles)
                    }
                    selectedFile={selectedImagePrev}
                    onRemove={(e) => {
                      e.stopPropagation();
                      setSelectedImagePrev(null);
                      setSelectedImageFile(null);
                      formik.setFieldValue("thumb", null);
                    }}
                    isLoading={isLoading}
                  />
                  {formik.touched.thumb && formik.errors.thumb ? (
                    <Typography
                      sx={{
                        color: "red",
                        fontWeight: "400",
                        fontSize: { xs: "14px", sm: "14px" },
                        marginTop: "5px",
                      }}
                    >
                      {formik.errors.thumb}
                    </Typography>
                  ) : null}
                </Box>
              </Grid>
              <Grid item xs={12}>
                <RadioGroup
                  value={formik.values.scheduleSelected}
                  name="scheduleSelected"
                  onChange={formik.handleChange}
                >
                  {isLoading ? (
                    <Skeleton height={"60px"} />
                  ) : (
                    <Box
                      sx={{
                        display: "flex",
                        width: "100%",
                        alignItems: "center",
                        justifyContent: "center",
                        gridGap: '10px'
                      }}
                    >
                      <FormControlLabel
                        value={"1"}
                        control={<Radio />}
                        label="Publish Now"
                      />
                      <FormControlLabel
                        value={"2"}
                        control={<Radio />}
                        label="Schedule Later"
                      />
                    </Box>
                  )}
                </RadioGroup>
              </Grid>
              <Grid item xs={12}>
                {formik.values.scheduleSelected === "2" && (
                  <CustomDateTimePicker
                    value={formik.values.timeSelected}
                    onChange={(newValue) =>
                      formik.setFieldValue("timeSelected", newValue)
                    }
                    error={
                      formik.touched.timeSelected && formik.errors.timeSelected
                    }
                  />
                )}
              </Grid>
              <Grid item xs={12}>
                <CustomDropDown
                  menuList={dropList}
                  value={formik.values.selectDropDown}
                  onChange={(e) => {
                    formik.setFieldValue("selectDropDown", e.target.value)
                  }
                  }
                  placeholder={"Select Media Type"}
                  errors={
                    formik.touched.selectDropDown &&
                    formik.errors.selectDropDown
                  }
                  isLoading={isLoading}
                />
              </Grid>
              <Grid item xs={12}>
                {formik.values.selectDropDown === 2 && (
                  <CustomTextField
                    label="Enter Video Link"
                    type="text"
                    placeholder="Link"
                    name="videoLink"
                    value={formik.values.videoLink}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    errors={formik.touched.videoLink && formik.errors.videoLink}
                    onKeyUp={formik.handleBlur}
                    sx={{
                      "& .MuiInputBase-root": {
                        background: "#F0F0F0",
                        borderRadius: "10px",
                      },
                    }}
                    isLoading={isLoading}
                  />
                )}
              </Grid>
              <Grid item xs={12}>
                <CustomTextField
                  label="Enter Title"
                  type="text"
                  placeholder="Title"
                  name="title"
                  value={formik.values.title}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  errors={formik.touched.title && formik.errors.title}
                  onKeyUp={formik.handleBlur}
                  sx={{
                    "& .MuiInputBase-root": {
                      background: "#F0F0F0",
                      borderRadius: "10px",
                    },
                  }}
                  isLoading={isLoading}
                />
              </Grid>
              <Grid item xs={12}>
                <CustomTextField
                  label="Enter Description"
                  type="text"
                  placeholder="Description"
                  name="description"
                  value={formik.values.description}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  onKeyUp={formik.handleBlur}
                  errors={
                    formik.touched.description && formik.errors.description
                  }
                  multiline
                  rows={5}
                  sx={{
                    "& .MuiInputBase-root": {
                      background: "#F0F0F0",
                      borderRadius: "10px",
                    },
                  }}
                  isLoading={isLoading}
                  
                />
              </Grid>
              <Grid item xs={12}>
                <Box
                  sx={{
                    py: 3,
                    px: 4,
                    display: "flex",
                    width: "100%",
                    justifyContent: "center",
                  }}
                >
                  <CustomLoaderButton
                    btnTitle={typeCheck ? "Update" : "Add"}
                    sx={{ width: "150px" }}
                    type="submit"
                    loading={isLoading}
                  />
                </Box>
              </Grid>
            </Grid>
          </form>
        </Box>
      </Box>
    </>
  );
};

export default AddAffirmativeTip;
